



















































import { Component, Watch } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import IconPlay from '@/assets/img/icons/play.svg?sprite'
import LazyloadIntersectionObservable from '@/mixins/LazyloadIntersectionObservable'
import Color from '@/constants/color'
import { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import Markdown from '@/components/Markdown.vue'
import MediaContent from '@/components/MediaContent.vue'
import Binpack360Content from '@/components/Binpack360Content.vue'
import SlideCarouselItem from '@/components/SlideCarouselItem.vue'
import KeyFigures from '@/components/KeyFigures.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ScrollerElement from '@/components/ScrollerElement.vue'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'

@Component({
    components: {
        IconPlay,
        ButtonIcon,
        Markdown,
        SlideCarouselItem,
        KeyFigures,
        MediaContent,
        ScrollerElement
    }
})
export default class SlideTimelineCarouselItem extends mixins(Slide, LazyloadIntersectionObservable) {
    @State popinVideoIsOpen!: boolean

    @Getter navigationIsAllowed!: boolean

    $refs!: {
        media: MediaContent
        title: HTMLElement
        content: HTMLElement
    }

    colorButton = Color.LIGHT

    playerProxy!: { progress: number }
    player!: Binpack360Content | undefined

    mounted() {
        this.playerProxy = { progress: 0 }
        this.player = this.$refs.media && (this.$refs.media.binpack360Content as Binpack360Content)
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemTimelineCarouselItem
    }

    get imageClasses(): string[] {
        const classes = [this.$style.image]
        if (this.walkerItem.media[0] && this.walkerItem.media[0].processable) {
            /*
             * if images are processable (png) put them in background
             */
            classes.push(this.$style.imageBackground)
        }
        return classes
    }

    /**
     * @override
     */
    onLazyloadObservableEnter() {
        // do not load binpack during animation for keeping good framerate
        if (this.transitionIsAnimated) return

        // Prevent triggering play while sliding too quickly
        this.player?.load()
    }

    populateEnterX({ timeline }: ScrollerElementAnimationEvent) {
        const parentRect = this.$el.getBoundingClientRect()
        const contentRect = this.$refs.content.getBoundingClientRect()

        timeline.fromTo(
            [this.$refs.title, this.$refs.content],
            {
                x: parentRect.x - contentRect.x - 250,
                opacity: 0.25
            },
            {
                x: 0,
                duration: 1,
                opacity: 1
            },
            0
        )

        if (this.player) {
            timeline.fromTo(
                this.playerProxy,
                {
                    progress: 0
                },
                {
                    progress: 0.5,
                    duration: 1,
                    ease: 'none',
                    onUpdate: () => {
                        this.player?.setCurrentImageFromRatio(this.playerProxy.progress)
                    }
                },
                0
            )
        }
    }

    populateLeaveX({ timeline }: ScrollerElementAnimationEvent) {
        if (this.player) {
            timeline.fromTo(
                this.playerProxy,
                {
                    progress: 0.5
                },
                {
                    progress: 1,
                    duration: 1,
                    ease: 'none',
                    onUpdate: () => {
                        this.player?.setCurrentImageFromRatio(this.playerProxy.progress)
                    }
                },
                0
            )
        }
    }

    toggleModalVideo(): void {
        this.$store.commit(
            ModuleNamespace.REMOTE + '/' + MutationRemoteType.POPIN_DOCUMENT,
            this.walkerItem.fullscreenMedia[0]
        )
    }

    goToSlide(index: number): void {
        this.$emit('go-to-slide', index)
    }

    // onEnterXComplete() {
    //     if (
    //         !this.transitionIsAnimated &&
    //         !this.transitionIsVisible &&
    //         (this.isCurrent || this.lazyloadObserverIsIntersecting)
    //     ) {
    //         this.player?.load()
    //     }
    // }

    @Watch('transitionIsAnimated')
    onTransitionIsAnimatedChange() {
        if (
            !this.transitionIsAnimated &&
            !this.transitionIsVisible &&
            (this.isCurrent || this.lazyloadObserverIsIntersecting)
        ) {
            this.player?.load()
        }
    }
}
